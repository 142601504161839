import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import WhiteLead from "../components/WhiteLead";
import LinkButton from "../components/Buttons/LinkButton";
import DienstleistungenTitle from "../DienstleistungenTitle";

interface DienstleistungenProps {
  data: {
    cms: {
      inhalt: {
        title: string;
        greenLead: string;
        uri: string;
        metaBeschreibung: string;
        siteId: number;
        dienstleistungenInhalte: [
          {
            visualImage: [{ url: string; title: string }];
            visualText: string;
            text: string;
            buttonLink: string;
            buttonText: string;
            buttonDownload: [{ url: string }];
          }
        ];
      };
    };
  };
}

export const DienstleistungenQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        uri
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_dienstleistungen_Entry {
          metaBeschreibung
          greenLead
          dienstleistungenInhalte {
            ... on CMS_dienstleistungenInhalte_bildTextSet_BlockType {
              visualImage {
                url
                title
              }
              visualText
              text
              buttonLink
              buttonText
              buttonDownload {
                url
              }
            }
          }
        }
      }
      lokalisierung: entry(siteId: [$siteId], type: "metaDatenLokalisierung") {
        ... on CMS_main_metaDatenLokalisierung_Entry {
          weitereProjekteAnzeigen
        }
      }
    }
  }
`;

const Dienstleistungen: React.FC<DienstleistungenProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.inhalt.siteId}
      title={data.cms.inhalt.title}
      //links={data.cms.inhalt.localized}
      beschreibung={
        data.cms.inhalt.metaBeschreibung
          ? data.cms.inhalt.metaBeschreibung
          : false
      }
    >
      <HeaderTitle inhalt={data.cms.inhalt.title} />
      <GreenLead inhalt={data.cms.inhalt.greenLead} />
      {data.cms.inhalt.dienstleistungenInhalte.map((obj, index) => (
        <div key={index}>
          {obj.visualText ? (
            <DienstleistungenTitle
              image={obj.visualImage[0]}
              text={obj.visualText}
            />
          ) : (
            ""
          )}
          <WhiteLead inhalt={obj.text} black />
          {obj.buttonLink ? (
            <LinkButton
              lessSpacingTop
              link={obj.buttonLink}
              text={obj.buttonText}
              key={index}
            />
          ) : obj.buttonDownload.length > 0 ? (
            <LinkButton
              lessSpacingTop
              link={obj.buttonDownload[0].url}
              download
              text={obj.buttonText}
              key={index}
            />
          ) : (
            ""
          )}
        </div>
      ))}
    </DefaultLayout>
  );
};

export default Dienstleistungen;
