import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "./styles/variables";

const DLTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 30px 10px;
  max-width: 1000px;
  margin: 0 auto;

  img {
    width: 100%;
    max-height: 229px;
  }

  .text {
    color: ${colors.green};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 1vw;

    h3 {
      font: 10vw / 13vw "Oswald Bold";
    }
  }

  @media screen AND (min-width: 500px) {
    padding: 30px;
  }

  @media screen AND (min-width: 800px) {
    .text {
      h3 {
        font: 68px / 95px "Oswald Bold";
      }
    }
  }
`;

interface DienstleistungenTitleProps {
  image: {
    url: string;
    title: string;
  };
  text: string;
}

const DienstleistungenTitle: React.FC<DienstleistungenTitleProps> = ({
  image,
  text,
}) => (
  <DLTitle>
    <div>
      {image && image.url && <img src={image.url} alt={image.title} />}
    </div>
    <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
  </DLTitle>
);

export default DienstleistungenTitle;
